import { SEOConfig } from '../types/seo';

export const seoConfig: SEOConfig = {
  siteName: "TrendVideo",
  siteUrl: "https://trendvideo.online",
  pages: {
    home: {
      title: "Генератор сценариев для видео с ИИ | TrendVideo - Создание вирусного контента",
      h1: "Умный генератор сценариев для вирусных видео",
      description: "Профессиональный генератор сценариев для видео с искусственным интеллектом. Создавайте вирусные сценарии для соцсетей за минуты. Попробуйте бесплатно!",
      focusKeyword: "генератор сценариев",
      keywords: [
        "генератор сценариев",
        "генератор сценариев для видео",
        "создание сценариев онлайн",
        "ai генератор сценариев",
        "написание сценариев",
        "программа для создания сценариев",
        "автоматический генератор сценариев",
        "нейросеть для сценариев",
        "сценарии для видео",
        "искусственный интеллект сценарии"
      ],
      canonicalUrl: "https://trendvideo.online",
      ogTitle: "Генератор сценариев для видео | Создавайте вирусный контент с ИИ",
      ogDescription: "Автоматизируйте создание сценариев для видео с помощью искусственного интеллекта. Экономьте время на создании контента!",
      ogImage: "https://trendvideo.online/og-image.jpg"
    },
    scriptWriting: {
      title: "Как написать сценарий для видео | Пошаговое руководство 2024",
      h1: "Как написать сценарий для видео",
      description: "Узнайте, как написать эффективный сценарий для видео. Пошаговое руководство, советы экспертов и практические примеры. Создавайте профессиональные сценарии прямо сейчас!",
      focusKeyword: "как написать сценарий",
      keywords: [
        "как написать сценарий",
        "написание сценария для видео",
        "структура видеосценария",
        "создание сценария",
        "сценарий для видеоролика",
        "написать сценарий самостоятельно",
        "правила написания сценария",
        "примеры сценариев",
        "план сценария",
        "видеосценарий"
      ],
      canonicalUrl: "https://trendvideo.online/how-to-write-script",
      ogTitle: "Как написать сценарий для видео | Полное руководство",
      ogDescription: "Научитесь писать эффективные сценарии для видео с нашим пошаговым руководством. Практические советы и примеры.",
      ogImage: "https://trendvideo.online/script-writing-guide.jpg"
    },
    reelsScript: {
      title: "Сценарий к Reels | Как создать вирусный контент для Instagram",
      h1: "Сценарий к Reels который привлекает внимание",
      description: "Узнайте, как создавать эффективные сценарии для Instagram Reels. Пошаговое руководство по написанию вирусных сценариев, примеры и шаблоны. Начните создавать популярный контент!",
      focusKeyword: "сценарий к рилс",
      keywords: [
        "сценарий к рилс",
        "как снимать рилс",
        "рилс сценарий",
        "instagram reels сценарий",
        "написать сценарий для рилс",
        "контент для рилс",
        "идеи для рилс",
        "вирусные рилс",
        "структура рилс",
        "рилс примеры"
      ],
      canonicalUrl: "https://trendvideo.online/reels-script",
      ogTitle: "Сценарий к Reels | Создавайте вирусный контент",
      ogDescription: "Создавайте эффективные сценарии для Instagram Reels. Увеличьте охваты и вовлеченность с помощью проверенных техник.",
      ogImage: "https://trendvideo.online/reels-script-guide.jpg"
    },
    aiScripts: {
      title: "AI генератор сценариев | Создание видеоконтента с помощью ИИ",
      h1: "AI генератор сценариев для видео",
      description: "Создавайте профессиональные сценарии для видео с помощью искусственного интеллекта. Автоматическая генерация уникальных сценариев с учетом вашей целевой аудитории.",
      focusKeyword: "ai генератор сценариев",
      keywords: [
        "ai генератор сценариев",
        "искусственный интеллект сценарии",
        "генератор текста ai",
        "нейросеть для сценариев",
        "автоматический сценарист",
        "создание сценариев онлайн",
        "ai помощник сценарист",
        "генерация сценариев",
        "написание сценариев ai",
        "умный генератор сценариев"
      ],
      canonicalUrl: "https://trendvideo.online/ai-scripts",
      ogTitle: "AI генератор сценариев | Создание видеоконтента с ИИ",
      ogDescription: "Используйте искусственный интеллект для создания профессиональных сценариев. Экономьте время и ресурсы.",
      ogImage: "https://trendvideo.online/ai-scripts-preview.jpg"
    },
    aiScriptGenerator: {
      title: "Генератор сценария нейросеть | ИИ для создания видеоконтента",
      h1: "Генератор сценария на основе нейросети",
      description: "Создавайте профессиональные сценарии для видео с помощью нейросети. Автоматическая генерация уникальных сценариев с учетом вашей целевой аудитории и стиля.",
      focusKeyword: "генератор сценария нейросеть",
      keywords: [
        "генератор сценария нейросеть",
        "нейросеть для написания сценариев",
        "искусственный интеллект сценарии",
        "ai генератор текста",
        "нейросеть контент",
        "автоматический сценарист",
        "генерация сценариев онлайн",
        "нейросеть для видео",
        "искусственный интеллект для контента",
        "ai помощник сценарист"
      ],
      canonicalUrl: "https://trendvideo.online/ai-script-generator",
      ogTitle: "Генератор сценария на основе нейросети | Создание контента с ИИ",
      ogDescription: "Используйте мощь искусственного интеллекта для создания уникальных сценариев. Экономьте время и ресурсы с нашим ИИ-генератором.",
      ogImage: "https://trendvideo.online/ai-generator-preview.jpg"
    },
    onlineScriptGenerator: {
      title: "Генератор сценариев онлайн | Создание сценариев для видео",
      h1: "Генератор сценариев онлайн",
      description: "Создавайте профессиональные сценарии для видео онлайн. Бесплатный генератор сценариев с искусственным интеллектом. Начните создавать качественные сценарии прямо сейчас!",
      focusKeyword: "генератор сценариев онлайн",
      keywords: [
        "генератор сценариев онлайн",
        "онлайн генератор текста",
        "создать сценарий онлайн",
        "бесплатный генератор сценариев",
        "программа для написания сценариев",
        "генератор идей для видео",
        "автоматический генератор текста",
        "создание сценариев бесплатно",
        "онлайн помощник сценариста",
        "написать сценарий онлайн"
      ],
      canonicalUrl: "https://trendvideo.online/online-script-generator",
      ogTitle: "Генератор сценариев онлайн | Создавайте сценарии бесплатно",
      ogDescription: "Используйте онлайн генератор сценариев для создания профессионального видеоконтента. Экономьте время и создавайте качественные сценарии.",
      ogImage: "https://trendvideo.online/online-generator-preview.jpg"
    },
    neuralScriptGenerator: {
      title: "Нейросеть для создания сценария | ИИ генератор видеосценариев",
      h1: "Нейросеть для создания сценария",
      description: "Используйте мощную нейросеть для создания профессиональных сценариев. Автоматическая генерация уникальных сценариев для видео с учетом вашей целевой аудитории.",
      focusKeyword: "нейросеть для создания сценария",
      keywords: [
        "нейросеть для создания сценария",
        "нейросеть сценарист",
        "искусственный интеллект сценарии",
        "нейросеть генератор текста",
        "ai сценарист",
        "написание сценариев нейросеть",
        "автоматический сценарист",
        "генерация сценариев нейросеть",
        "нейросеть для видео",
        "создание сценария онлайн"
      ],
      canonicalUrl: "https://trendvideo.online/neural-script-generator",
      ogTitle: "Нейросеть для создания сценария | Автоматическая генерация",
      ogDescription: "Создавайте профессиональные сценарии для видео с помощью нейросети. Экономьте время и ресурсы.",
      ogImage: "https://trendvideo.online/neural-generator-preview.jpg"
    }
  }
};
